import Vue from 'vue';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

export type Alert = { title: string; type: string };

@Module({ namespaced: true })
export default class Ui extends VuexModule {
  public loading = false;

  @Mutation
  public setLoader(value: boolean): void {
    this.loading = value;
  }

  @Action
  public alert({ title, type }: Alert): void {
    Vue.notify({
      group: 'alerts',
      type,
      title,
    });
  }
}
