import Vue from 'vue';
import {
  VAutocomplete,
  VDatePicker,
  VListItem,
  VListItemTitle,
  VTextField,
  VTextarea,
} from 'vuetify/lib';
import TableGeneric from '@/components/tableGeneric/tableGeneric.vue';
import HeaderTitle from '@/components/headerTitle.vue';
import BuildForm from '@/components/buildForm.vue';
import CheckYN from '@/components/checkYN.vue';
import GMap from '@/components/gMap.vue';

Vue.component('VTextField', VTextField);
Vue.component('VDatePicker', VDatePicker);
Vue.component('VListItem', VListItem);
Vue.component('VAutocomplete', VAutocomplete);
Vue.component('VListItemTitle', VListItemTitle);
Vue.component('VTextarea', VTextarea);
Vue.component('TableGeneric', TableGeneric);
Vue.component('HeaderTitle', HeaderTitle);
Vue.component('BuildForm', BuildForm);
Vue.component('CheckYN', CheckYN);
Vue.component('GMap', GMap);
