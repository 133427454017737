
import { Component, Prop, Vue } from 'vue-property-decorator';

declare const google: any;

@Component({})
export default class GMap extends Vue {
  map: any;
  @Prop({ required: true })
  pos!: { lat: number, long: number };

  initMap(): any {
    const element: any = this.$refs.googleMap;
    return new google.maps.Map(element, {
      zoom: 15,
      center: new google.maps.LatLng(this.pos.lat, this.pos.long),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    });
  }

  mounted() {
    this.map = this.initMap();
    new google.maps.Marker({
      position: new google.maps.LatLng(this.pos.lat, this.pos.long),
      map: this.map
    });
  }
}
