import _Vue from 'vue';
import axios, { AxiosStatic } from 'axios';
import { auth, validatePermission } from '@/config/lib';

declare const google: any;

export function HttpClient(Vue: typeof _Vue) {
  console.log(process.env.VUE_APP_API_URL);
  Vue.prototype.$http = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/api/v1`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  Vue.prototype.$httpSetBearer = () => {
    const data = auth.get();
    Vue.prototype.$http.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${data.token.access_token}`;
  };
  Vue.prototype.$permission = validatePermission;
  Vue.prototype.$addScriptMap = () => {
    if (typeof google === 'undefined') {
      const GScript = document.createElement('script');
      GScript.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_G_MAP_KEY}`,
      );
      document.body.appendChild(GScript);
    }
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosStatic;
    $httpSetBearer: () => void;
    $auth: any;
    $permission: (permissions: string[]) => boolean;
    $addScriptMap: () => void;
  }
}
