export const ProjectsRoutes = [
  {
    path: '/admin/projects',
    name: 'projects',
    component: () => import('@/views/admin/projects/projects.vue'),
    meta: {
      acl: ['m:projects'],
    },
  },
  {
    path: '/admin/activities',
    name: 'activities',
    component: () => import('@/views/admin/projects/activities.vue'),
    meta: {
      acl: ['m:activities'],
    },
  },
];
