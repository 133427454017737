
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { VForm } from '@/config/types';

@Component({})
export default class BuildForm extends Vue {
  @Prop()
  formFields!: any[];
  @Prop()
  selectInfo!: any;
  @Model('change')
  form!: any;
  get selectData() {
    return this.selectInfo || {};
  }
  get refForm() {
    return this.$refs.form as VForm;
  }
  isValidForm = false;

  async validate() {
    if (this.$refs.form) {
      await this.refForm.validate();
    }
    return this.isValidForm;
  }

  async reset() {
    if (this.$refs.form) {
      await this.refForm.reset();
    }
  }
}
