const datesFields = ['dateHire'];

export const auth = {
  get(): any {
    let data = localStorage.getItem('access_user');
    if (data) {
      data = JSON.parse(data);
      return data;
    }
    return false;
  },
  //Set data for auth session
  set(data: { token: string; user: any }) {
    localStorage.setItem('access_user', JSON.stringify(data));
  },
  delete() {
    localStorage.removeItem('access_user');
  },
  //Getter for if is auth
  get isAuth() {
    return !!this.get();
  },
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const parseJwt = () => {
  const authData = auth.get();
  if (authData) {
    return JSON.parse(atob(authData.token.access_token.split('.')[1]));
  }
};

export const validatePermission = (permissions: [string]) => {
  const userPermissions = parseJwt().user.permissions;
  return permissions.every((permission: string) =>
    userPermissions.includes(permission),
  );
};

export const prefetchDates = (form: any) => {
  const newForm = { ...form };
  for (const item of Object.keys(form)) {
    if (datesFields.every((date) => date === form[item])) {
      newForm[item] = new Date(form[item]);
    }
  }
  return newForm;
};

export const ST_core = {
  get(key: string): any {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return false;
  },
  set(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  },
  delete(key: string) {
    localStorage.removeItem(key);
  },
};
