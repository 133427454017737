
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component({})
export default class CheckYN extends Vue {
  @Prop()
  label!: string;
  @VModel()
  field!: string;
}
