
import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { auth, ST_core } from '@/config/lib';

const ui = namespace('ui');
const authS = namespace('auth');

@Component({})
export default class App extends Vue {
  @ui.State
  loading!: boolean;
  @authS.State
  auth!: { isAuth: boolean, user: any };
  @authS.Mutation
  setUser!: (userReq: any) => void;
  @authS.Mutation
  logout!: () => void;

  saveTheme(val: boolean) {
    ST_core.set('ThemeAPP', val);
  }

  created() {
    const theme = ST_core.get('ThemeAPP');
    if (theme) {
      this.$vuetify.theme.dark = theme;
    }
    if (this.auth.isAuth) {
      this.setUser(auth.get());
      this.$httpSetBearer();
    }
  }
}
