import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import router from '@/router';
import { auth, ST_core } from '@/config/lib';

@Module({ namespaced: true })
export default class Auth extends VuexModule {
  public auth: any = {
    user: null,
    token: null,
    isAuth: auth.isAuth,
  };

  @Mutation
  async setUser(userReq: any) {
    auth.set(userReq);
    this.auth.user = userReq.user;
    this.auth.token = userReq.token;
    this.auth.isAuth = true;
  }

  @Mutation
  async logout() {
    auth.delete();
    this.auth.user = null;
    this.auth.token = null;
    this.auth.isAuth = false;
    const theme = ST_core.get('ThemeAPP');
    localStorage.clear();
    sessionStorage.clear();
    if (theme) {
      ST_core.set('ThemeAPP', theme);
    }
    await router.push('/login');
  }
}
