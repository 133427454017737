export const UsersRoutes = [
  {
    path: '/admin/users',
    name: 'users',
    component: () => import('@/views/admin/users/users.vue'),
    meta: {
      acl: ['m:users', 'r:users', 'r:roles'],
    },
  },
  {
    path: '/admin/users/roles',
    name: 'roles',
    component: () => import('@/views/admin/users/roles.vue'),
    meta: {
      acl: ['r:roles', 'r:permissions'],
    },
  },
  {
    path: '/admin/users/permissions',
    name: 'permissions',
    component: () => import('@/views/admin/users/permissions.vue'),
    meta: {
      acl: ['r:permissions'],
    },
  },
  {
    path: '/admin/notifications',
    name: 'notifications',
    component: () => import('@/views/admin/users/notifications.vue'),
  },
  {
    path: '/admin/profile',
    name: 'profile',
    component: () => import('@/views/auth/profile.vue'),
  },
];
