<template>
  <div class='d-flex align-center'>
    <v-btn icon='icon' fab='fab' @click='() => $router.go(-1)'>
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: 'headerTitle',
  props: ['title']
};
</script>

<style scoped>

</style>
