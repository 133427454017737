import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/src/locale/es';
import moment from 'moment';

Vue.use(Vuetify);

Vue.filter('moment', (value: string, arg: string) => {
  const format = arg || 'DD-MM-YYYY';
  return value ? moment(value).format(format) : 'No hay fecha';
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
});
