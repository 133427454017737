import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { UiMixin } from '@/mixins/ui-mixin';
import { Filters } from '@/config/types';

@Component
export class GridTable extends Mixins<UiMixin>(UiMixin) {
  path = '';
  filters: Filters = { rows: 10, page: 1 };
  pages = 0;
  itemsTotal = 0;
  items = [];

  async listItems() {
    this.setLoader(true);
    try {
      const req: any = await this.$http.get(`/${this.path}`, {
        params: { ...this.parseFilters() },
      });
      this.items = req.data.docs;
      this.itemsTotal = req.data.count;
      this.pages = Math.ceil(req.data.count / this.filters.rows);
      this.setLocalStorage();
    } catch (e: any) {
      if (e.response) {
        this.alert(
          `${
            e.response.status === 403
              ? 'Error de permisos'
              : 'Error en el servicio'
          }`,
          'error',
        );
      } else {
        this.alert('Hubo un error interno', 'error');
      }
    }
    this.setLoader(false);
  }

  parseFilters() {
    const filtersParsed: any = {};
    Object.keys(this.filters).map((filter) => {
      if ((this.filters as any)[filter]) {
        filtersParsed[filter] = (this.filters as any)[filter];
      }
    });
    return filtersParsed;
  }

  setLocalStorage() {
    localStorage.setItem(`${this.path}-stg`, JSON.stringify(this.filters));
  }

  getLocalStorage() {
    const data = localStorage.getItem(`${this.path}-stg`);
    if (data) {
      const filters = JSON.parse(data);
      this.filters = { ...filters };
    }
  }

  async changePage(value: number | string) {
    if (value !== this.filters.page) {
      this.filters.page = +value;
      await this.listItems();
    }
  }
}
