import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { auth, parseJwt, validatePermission } from '@/config/lib';
import store from '@/store';
import { UsersRoutes } from '@/router/modules/users-routes';
import { ProjectsRoutes } from '@/router/modules/projects-routes';
import { SchedulesRoutes } from '@/router/modules/schedules-routes';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/login.vue'),
  },
  {
    path: '/not-permission',
    name: 'notPermission',
    component: () => import('@/views/not-permission.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/dashboard.vue'),
  },
  ...UsersRoutes,
  ...ProjectsRoutes,
  ...SchedulesRoutes,
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.state.ui.loading = false;
  const isLogin = to.name === 'login';

  if (auth.isAuth) {
    const jwt = parseJwt();
    const exp = jwt.exp * 1000;
    const now = new Date().getTime();
    if (exp < now - 900000) {
      await store.dispatch('ui/alert', {
        title: 'Su sesión ha expirado',
        type: 'error',
      });
      store.commit('auth/logout');
    }
  }

  if (auth.isAuth && isLogin) {
    next({ path: '/admin' });
  }

  if (auth.isAuth && !isLogin) {
    if (to.meta && to.meta.acl) {
      const aclIsEmpty = !to.meta.acl.length;
      if (!aclIsEmpty && !validatePermission(to.meta.acl)) {
        next({ path: '/not-permission' });
      }
    }
  }

  if (!auth.isAuth && !isLogin) {
    next({ path: '/login' });
  }

  return next();
});

export default router;
