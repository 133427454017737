import Vue from 'vue';
import Vuex from 'vuex';
import Ui from '@/store/modules/ui';
import Auth from '@/store/modules/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ui: Ui,
    auth: Auth,
  },
});
