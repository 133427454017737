import { Component, Mixins, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { prefetchDates } from '@/config/lib';
import { VForm } from '@/config/types';
import { GridTable } from '@/mixins/grid-table';

@Component({ name: 'tableGeneric' })
export default class TableGenericScript extends Mixins<GridTable>(GridTable) {
  @Prop()
  module!: string;
  @Prop()
  headers!: any;
  @Prop()
  title!: any;
  @Prop()
  formFields!: any;
  @Prop()
  selectInfo!: any;
  @Prop()
  noBack!: any;
  @Prop()
  details!: any;
  @Prop()
  filtersView!: any;
  dialogForm = false;
  deleteAlert = false;
  edit = false;
  form: any = {};

  get refForm() {
    return this.$refs.form as VForm;
  }

  async createItem() {
    const form = prefetchDates(this.form);
    const isValidForm = await this.refForm.validate();
    if (!isValidForm) return;
    this.setLoader(true);
    try {
      await this.$http.post(`/${this.path}`, {
        ...form,
      });
      await this.listItems();
      await this.cleanForm();
      this.alert('Creado correctamente', 'success');
    } catch (e) {
      this.alert('Error creando el item', 'error');
    }
    this.setLoader(false);
  }

  async editItem() {
    const form = prefetchDates(this.form);
    const isValidForm = await this.refForm.validate();
    if (!isValidForm) return;
    this.setLoader(true);
    try {
      await this.$http.patch(`/${this.path}/${this.form._id}`, {
        ...form,
      });
      await this.listItems();
      await this.cleanForm();
      this.alert('Editado correctamente', 'success');
    } catch (e) {
      this.alert('Error actualizando el item', 'error');
    }
    this.setLoader(false);
  }

  async deleteItem() {
    this.setLoader(true);
    try {
      await this.$http.delete(`/${this.path}/${this.form._id}`);
      await this.listItems();
      await this.cleanForm();
      this.alert('Eliminado correctamente', 'success');
    } catch (e) {
      this.alert('Error eliminando el item', 'error');
    }
    this.setLoader(false);
  }

  async cleanForm() {
    this.edit = false;
    if (this.$refs.form) {
      await this.refForm.reset();
    }
    delete this.form['_id'];
    this.dialogForm = false;
    this.deleteAlert = false;
  }

  relatedEditForm(item: any) {
    const form: any = {
      _id: item._id,
    };
    for (const field of this.formFields) {
      if (field.type === 'v-date-picker') {
        form[field.field] = moment(item[field.field])
          .utc()
          .format('YYYY-MM-DD');
      } else if (field.type === 'v-autocomplete' && !field.multiple) {
        form[field.field] = item[field.field]._id;
      } else {
        form[field.field] = item[field.field];
      }
    }
    this.form = { ...form };
    this.edit = true;
    this.dialogForm = true;
  }

  relatedDeleteForm(item: any) {
    this.relatedEditForm(item);
    this.dialogForm = false;
    this.deleteAlert = true;
  }

  async mounted() {
    this.path = this.module;
    this.getLocalStorage();
    if (this.filtersView) {
      this.filters = { ...this.filters, ...this.filtersView };
    }
    await this.listItems();
  }
}
