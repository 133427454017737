import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Alert } from '@/store/modules/ui';

const ui = namespace('ui');

@Component
export class UiMixin extends Vue {
  @ui.Mutation
  setLoader!: (state: boolean) => void;
  @ui.Action('alert')
  alertFoo!: ({ title, type }: Alert) => void;

  alert(title: string, type?: string) {
    this.alertFoo({ title, type: type ? type : 'error' });
  }
}
