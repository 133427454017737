export const SchedulesRoutes = [
  {
    path: '/admin/register-schedule',
    name: 'registerSchedules',
    component: () => import('@/views/admin/schedules/schedules-user.vue'),
    meta: {
      acl: ['m:schedules'],
    },
  },
  {
    path: '/admin/registers-schedules-users',
    name: 'registerSchedulesAdmin',
    component: () => import('@/views/admin/schedules/schedules-admin.vue'),
    meta: {
      acl: ['m:schedules-admin'],
    },
  },
];
